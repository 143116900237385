import { useEffect, useRef } from 'react';

export function useCookieDeclaration() {
  const loadedRef = useRef(true);
  useEffect(() => {
    if (!loadedRef.current) return;
    const element = document.getElementById(
      'CookieDeclaration'
    ) as HTMLDivElement;
    if (!element) return;
    const src = element.getAttribute('data-src');
    if (!src) return;
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => {
      console.log('Cookiebot Declaration initialized');
      loadedRef.current = false;
    };
    element.appendChild(script);
  }, []);
}
